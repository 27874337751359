import { template as template_adde760995264c2abe96d015e3f7ea3d } from "@ember/template-compiler";
const FKText = template_adde760995264c2abe96d015e3f7ea3d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
